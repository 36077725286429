.ItemWrapper{
    display: flex;
    padding: 20px;
}
.ItemDetails{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin: 0 20px;
}
.ItemWrapper > .ItemDetails > .ItemName{
    font-size: 14px;
    color: #666;
}
.ItemWrapper > .ItemDetails > .ItemPrice{
    font-size: 16px;
    font-weight: bold;
}

.CartActionButtons{
    display: flex;
}