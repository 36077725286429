.RadioButton{
    display: block;
    position: relative;
    cursor: pointer;
    padding-left: 25px;
}
.RadioButton input[type=radio]{
    position: absolute;
    opacity: 0;
    cursor: pointer;
}
.RadioButton .Checkmark{
    position: absolute;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    background: transparent;
    border-radius: 50%;
    border: 2px solid #333;
}
.RadioButton:hover input ~ .Checkmark{
    background: #cecece;
}
.RadioButton input:checked ~ .Checkmark{
    background: #fff;
}
.Checkmark:after{
    content: '';
    position: absolute;
    display: none;
}
.RadioButton input:checked ~ .Checkmark:after{
    display: block;
}
.RadioButton .Checkmark:after{
    top: 2px;
    left: 2px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #252525;
}