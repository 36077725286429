.ThankyouPage{
    padding: 20px;
}
.ThankyouPage h1{
    font-size: 40px;
    font-family: 'Lato', sans-serif;
    color: #252525;
    opacity: 0.7;
    margin-bottom: 20px;
}
.OrderId{
    font-size: 16px;
    font-weight: bold;
    color: #666;
}
.SmallText{
    font-size: 12px;
    margin: 10px 0;
}