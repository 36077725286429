.QunantityControl{
    display: flex;
    align-items: center;
}
.QunantityControl input{
    width: 50px;
    height: 30px;
    border-radius: 2px;
    border: 1px solid #cecece;
    text-align: center;
    font-weight: bold;
    font-family: 'Lato', sans-serif;
}
.QunantityControl button{
    background: transparent;
    color: #333;
    width: 30px;
    height: 30px;
    outline: none;
    border: 1px solid #cecece;
    border-radius: 50%;
    margin: 0 10px;
    font-weight: bold;
    font-family: 'Lato', sans-serif;
    font-size: 20px;
    cursor: pointer;
}