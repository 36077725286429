.Order{
    width: 100%;
    border: 1px solid #cecece;
    margin: 5px 0;
}
.OrderHeader{
    padding: 10px;
    background: #cecece;
}
.OrderHeader > a{
    display: inline-block;
    padding: 5px 10px;
    background:rgb(241, 92, 34) none repeat scroll 0% 0%;
    color: #fff;
    text-transform: uppercase;
    border-radius: 2px;
    font-size: 12px;
    letter-spacing: 1px;
    font-family: 'Varela Round', sans-serif;
}
.OrderDescription{
    display: flex;
    padding: 10px;
    justify-content: space-between;
    border: 1px solid #cecece;
    font-size: 14px;
    color:  #555;
    font-family: 'Varela Round', sans-serif;

}
.od1{
    width: 40%;
}
.odtitle{
    font-weight: bold;
    font-family: 'Varela Round', sans-serif;
    font-size: 18px;
}
.odp{
    display: inline-block;
    padding: 5px 10px;
    border-radius: 2px;
    color: #fff;
    margin: 5px 10px;
    text-transform: capitalize;
    font-family: 'Varela Round', sans-serif;
}
.OrderFooter{
    border: 1px solid #cecece;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    color: #555;
    font-family:'Varela Round', sans-serif;
}
.ImageContainer{
    max-width: 200px;
    max-height: 200px;
    position: relative;
}