.InputGroup{
    padding: 10px 0;
}
.InputGroup > input{
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.InputGroup > input::placeholder{
    font-family: 'Lato';
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
    outline: none;
}